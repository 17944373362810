import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'
import { Box } from 'reflexbox'

import NewsletterSubscription from 'components/NewsletterSubscription'
import SocialLink from 'components/SocialLink'
import PhoneIcon from 'public/icons/phone.svg'
import MapPinIcon from 'public/icons/map-pin.svg'
import ArrowIcon from 'public/icons/arrow.svg'
import Logo from 'public/icons/logo.svg'

import layoutTheme from 'theme/layout-theme'
import style from './Footer.style'

const FooterStyled = styled.div(props => ({ ...style(props) }))

const Footer = ({ t }) => {
    return (
        <FooterStyled>
            <Box theme={layoutTheme} variant="center1360" px={20}>
                <div className="InfoBox-Newsletter">
                    <h5 className="footerTitle">{t('contact')}</h5>
                    <div className="infoBox">
                        <div className="icon">
                            <PhoneIcon />
                        </div>

                        <p className="content">+385 (0)91 346 - 7775</p>
                    </div>

                    <div className="infoBox">
                        <a
                            href="https://www.google.com/maps/dir//Merk+%26+Merk+Yacht+Service+Tribunj+d.o.o.,+Jurjevgradska+ul.+2,+22212,+Tribunj/@43.7531272,15.7463553,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x1334d827f6c3b605:0x94167fc4a424b6ed!2m2!1d15.748544!2d43.7531272!3e0"
                            className="block-link desktop"
                            target="_blank"
                            rel="noreferrer noopener">
                            View directions on a map
                        </a>
                        <div className="icon">
                            <MapPinIcon />
                        </div>

                        <p className="content">
                            Jurjevgradska 2, Marina Tribunj
                            <br />
                            HR-22212 Tribunj, Croatia
                        </p>
                    </div>

                    <div className="Newsletter">
                        <h5 className="footerTitle">{t('newsletter')}</h5>
                        <p>{t('receive-exclusive-content')}</p>
                        <NewsletterSubscription placeholder={t('placeholder-subscribe')} />
                    </div>
                </div>

                <div className="Menu-LogoSocial">
                    <h5 className="footerTitle">{t('menu')}</h5>
                    <div className="Menu">
                        <div className="col-1">
                            <Link href="/">
                                <a>
                                    {t('home')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/buy-a-yacht">
                                <a>
                                    {t('buy-a-yacht')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/sell">
                                <a>
                                    {t('sell-a-yacht')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/manage">
                                <a>
                                    {t('manage-a-yacht')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/configure">
                                <a>
                                    {t('service-calculator')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/yacht-news">
                                <a>
                                    Yacht News <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/about">
                                <a>
                                    {t('about-us')} <ArrowIcon />
                                </a>
                            </Link>
                        </div>
                        <div className="col-2">
                            <Link href="/newsletter">
                                <a>
                                    {t('newsletter')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/contact">
                                <a>
                                    {t('contact')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/privacy-policy">
                                <a>
                                    {t('privacy-policy')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/terms-and-conditions">
                                <a>
                                    {t('terms-and-conditions')} <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/eu-funds">
                                <a>
                                    EU funds <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/faq">
                                <a>
                                    FAQ <ArrowIcon />
                                </a>
                            </Link>
                            <Link href="/impressum">
                                <a>
                                    {t('impressum')} <ArrowIcon />
                                </a>
                            </Link>
                        </div>
                    </div>

                    <div className="Logo-Social">
                        <div className="Logo">
                            <Link href="/">
                                <a>
                                    <Logo />
                                </a>
                            </Link>
                        </div>

                        <div className="Social">
                            <p className="title">{t('follow-us')}</p>

                            <div className="icons">
                                <SocialLink link="https://www.facebook.com/yachtsalesmerk/" title="Facebook" />
                                <SocialLink
                                    link="https://www.instagram.com/merkandmerk_yachtingboutique/"
                                    title="Instagram"
                                />
                                {/*
                                    <SocialLink
                                        link="https://www.youtube.com"
                                        title="YouTube"
                                    />

                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </FooterStyled>
    )
}

Footer.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(Footer))
